<template>
  <main-template>
    <v-alert
      v-if="countImagem"
      class="animate__animated animate__headShake"
      style="position: absolute; right: 15px; width: 420px; height: 50px"
      type="error"
      >Você só pode carregar somente uma <strong>IMAGEM</strong>.</v-alert
    >
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text :to="{ name: 'agenda.index' }">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Agenda
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <template>
          <!--UPLOAD-->
          <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
            <h4>Carregar imagem</h4>
            <div class="dropbox">
              <input
                type="file"
                multiple
                :name="uploadFieldName"
                :disabled="isSaving"
                @change="
                  filesChange($event.target.files);
                  fileCount = $event.target.files.length;
                "
                accept="image/*"
                class="input-file"
              />
              <p v-if="isInitial">
                Arraste uma imagem aqui para começar<br />
                ou clique para navegar
              </p>
              <v-col class="text-center" v-if="isSaving">
                <img class="text-center" width="150x" :src="base64Img" />
                <p>Imagem carregada!</p>
              </v-col>
            </div>
          </form>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="8">
            <ValidationProvider name="turma" rules="required" v-slot="{ errors }">
              <v-select
                :items="gestoes"
                label="Selecione uma turma"
                v-model="gestao"
                :item-text="(item) => item.turma.descricao"
                :item-value="(item) => item.turma_id"
                return-object
                outlined
                :error-messages="errors"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="4">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataFormatada"
                  label="Data"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.data"
                @input="(menu = false), (dataFormatada = formatar(form.data))"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="título" rules="required" v-slot="{ errors }">
              <v-text-field
                counter="35"
                hint="Você só pode digitar até 25 caracteres"
                label="Título"
                v-model="form.titulo"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="conteúdo" rules="required" v-slot="{ errors }">
              <v-textarea
                outlined
                name="input-7-1"
                label="Atividade de casa/sala"
                v-model="form.conteudo"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { dateFormatParam } from "../../plugins/filters";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      franquiaAtual: false,
      countImagem: false,
      autoUpdate: true,
      isUpdating: false,
      menu: false,
      // Mesmas variaveis usadas no controller se você mudar terá que colocar o mesmo nome
      gestoes: [],
      base64Img: "",
      name: "Midnight Crew",
      form: {
        titulo: "",
        conteudo: "",
        turma_id: "",
        instrutor_id: "",
        data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      submittingForm: false,
      items: [],
      title: "The summer breeze",
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      gestao: null,
      dateFormatted: "",
      agendaUpload: null,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  methods: {
    formatar(date) {
      return dateFormatParam(date, "d/m/y");
    },
    async submitForm() {
      try {
        this.form.turma_id = this.gestao ? this.gestao.turma_id : null;
        this.form.instrutor_id = this.gestao ? this.gestao.instrutor_id : null;
        if (this.editing) {
          const response = await this.$services.agendasService.update(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Agenda editada com sucesso!");
          this.$router.push({ name: "agenda.index" });
        } else {
          const response = await this.$services.agendasService.store(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Agenda criada com sucesso!");
          this.$router.push({ name: "agenda.index" });
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    filesChange(fileList) {
      // handle file changes
      if (!fileList.length) return;
      // append the files to FormData
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.agendaUpload = fileList[x];
        return fileList[x];
      });
      this.currentStatus = STATUS_SAVING;
    },
    async loadData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const response = await this.$services.agendasService.turmas();
        this.gestoes = response.gestoesDeAulas;
        if (this.editing) {
          const response = await this.$services.agendasService.find(
            parseInt(this.$route.params.agenda_id, 10)
          );
          this.form = response;
          this.dataFormatada = this.formatar(this.form.data);
          this.gestao = {
            id: response.id || null,
            turma_id: response.turma_id || null,
            instrutor_id: response.instrutor_id || null,
            turma: {
              descricao: response.turma.descricao || "",
            },
          };
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async uploadImage(id) {
      try {
        await this.$services.imageService.uploadImage(
          "notifiq/agendas/upload-image",
          this.agendaUpload,
          id
        );
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
    this.reset();
    this.loadData();
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
