<template>
  <v-container class="pa-4 text-center">
    <v-card>
      <v-row class="fill-height" align="center" justify="space-around">
        <v-col cols="12" md="4">
          <h4 class="ma-3">Imagem do Aluno</h4>
          <v-hover v-slot="{ hover }">
            <v-card
              style="width: 205px; margin-left: 20%"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img :src="imgAlunoCard" height="205px" width="205px">
                <v-card-title
                  v-if="imgAlunoCard && imgAlunoCard != '/placeholde-aluno.jpeg'"
                  class="text-h6 white--text"
                >
                  <v-row class="fill-height flex-column" justify="space-between">
                    <div class="align-self-center">
                      <v-btn
                        style="margin-bottom: 10px"
                        @click="abrirImagem(imgAluno)"
                        :class="{ 'show-btns-down': hover }"
                        :color="transparent"
                        plain
                      >
                        <v-icon :class="{ 'show-btns-down': hover }" :color="transparent">
                          file_download
                        </v-icon>
                        Baixar
                      </v-btn>
                      <v-btn
                        @click="removerImagemAluno"
                        :class="{ 'show-btns': hover }"
                        :color="transparent"
                        plain
                      >
                        <v-icon :class="{ 'show-btns': hover }" :color="transparent">
                          delete
                        </v-icon>
                        Remover
                      </v-btn>
                    </div>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
          <v-file-input
            v-model="fileImagemAluno"
            accept="image/png, image/jpeg"
            :max-size="2097152"
            class="mt-5"
            label="Imagem do Aluno"
            filled
            prepend-icon="mdi-camera"
            outlined
            @change="uploadImagemAluno($event)"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-card>
    <e-modal-cortar-imagem
      :dialogProp="cortarImagemDialogAluno"
      :typeImage="'imagem-aluno'"
      :img="imgAluno"
      @atualizar-imagem-aluno="atualizarImagemAluno($event)"
    />

    <v-dialog v-model="dialogAvisoMobile" width="60%">
      <v-alert class="clickable" border="left" elevation="3" type="warning"
        >A imagem só será removida após você clicar em enviar dados, no final da aba</v-alert
      >
    </v-dialog>
  </v-container>
</template>

<script>
import EModalCortarImagem from "./EModalCortarImagem.vue";

export default {
  name: "EUploadsImages",
  data: () => ({
    dialogAvisoMobile: false,
    fileImagemAluno: null,
    fileImagemCarteira: null,
    imgAluno: `${process.env.BASE_URL}placeholde-aluno.jpeg`,
    file_carteira: null,
    icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
    transparent: "rgba(255, 255, 255, 0)",
    cortarImagemDialogCarteira: false,
    cortarImagemDialogAluno: false,
    imgCarteiraVacina: `${process.env.BASE_URL}placeholder-carteira.png`,
    imgAlunoCard: `${process.env.BASE_URL}placeholde-aluno.jpeg`,
    imgCarteiraVacinaCard: `${process.env.BASE_URL}placeholder-carteira.png`,
  }),
  props: {
    dadosImgCarteira: {
      type: String,
    },
    dadosImgAluno: {
      type: String,
    },
  },
  watch: {
    dadosImgCarteira(value) {
      this.imgCarteiraVacina = value;
      this.imgCarteiraVacinaCard = value;
    },
    dadosImgAluno(value) {
      this.imgAluno = value;
      this.imgAlunoCard = value;
    },
  },
  components: {
    EModalCortarImagem,
  },
  methods: {
    abrirImagem(img) {
      window.open(img, "_blank");
    },

    // carteira de vacina
    removerImagemCarteira() {
      this.dialogAvisoMobile = true;
      this.imgCarteiraVacina = `${process.env.BASE_URL}placeholder-carteira.png`;
      this.$emit("imagem-carteira-form", { url: "", name: "" });
    },

    atualizarImagemCarteira(dado) {
      this.imgCarteiraVacina = dado.url;
      this.cortarImagemDialogCarteira = false;
      if (dado.url === null) {
        this.fileImagemCarteira = null;
        this.imgCarteiraVacina = `${process.env.BASE_URL}placeholder-carteira.png`;
      }
      this.$emit("imagem-carteira-form", dado);
    },

    // imagem aluno
    removerImagemAluno() {
      this.dialogAvisoMobile = true;
      this.imgAluno = `${process.env.BASE_URL}placeholde-aluno.jpeg`;
      this.imgAlunoCard = `${process.env.BASE_URL}placeholde-aluno.jpeg`;
      this.$emit("imagem-aluno-form", { url: "", name: "" });
    },
    async uploadImagemAluno(file) {
      const data = await this.$services.alunosService.uploadImageAluno(file);
      if (!data.nameFile) {
        this.cortarImagemDialogAluno = false;
        this.imgAluno = `${process.env.BASE_URL}placeholde-aluno.jpeg`;
      } else {
        this.imgAluno = `${process.env.VUE_APP_API_HOST}${data.nameFile}`;
        this.cortarImagemDialogAluno = true;
      }
    },

    atualizarImagemAluno(dado) {
      this.imgAluno = dado.url;
      this.imgAlunoCard = dado.url;
      this.cortarImagemDialogAluno = false;
      // if (dado.url === null) {
      //   this.fileImagemAluno = null;
      //   this.imgAluno = `${process.env.BASE_URL}placeholde-aluno.jpeg`;
      //   this.imgAlunoCard = `${process.env.BASE_URL}placeholde-aluno.jpeg`;
      // }
      this.$emit("imagem-aluno-form", dado);
    },
  },
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}

.show-btns {
  color: rgb(173, 9, 9) !important;
  background-color: white;
}
.show-btns-down {
  color: rgb(8, 125, 66) !important;
  background-color: white;
  margin-right: 4px;
}
</style>
