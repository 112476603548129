<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Vínculo
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="8">
            <ValidationProvider name="Professor" rules="required" v-slot="{ errors }">
              <!-- aqui -->
              <e-label>Professor</e-label>
              <e-autocomplete
                :items="professoresList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => `${val.cpf} - ${val.nome} - ${val.user.roles[0].name}`"
                :error-messages="errors"
                :loading="carregandoProfessores"
                v-model="form.instrutor_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
              <e-label>Tipo do Professor</e-label>
              <v-select
                :items="tipos"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                label="Selecione uma opção"
                v-model="form.tipo_professor"
                :error-messages="form.errors ? form.errors : errors"
                dense
                solo
              ></v-select>
            </ValidationProvider>
          </v-col>

          <!-- <v-col class="pt-8 pb-0" cols="6"> </v-col> -->
        </v-row>
        <v-row
          class="animate__animated animate__zoomIn"
          style="animation-duration: 0.5s"
          v-for="relacao of relacoes"
          :key="relacoes.indexOf(relacao)"
        >
          <v-col class="pt-0 pb-0" cols="3">
            <ValidationProvider name="Turma" rules="required" v-slot="{ errors }">
              <e-label>Turma</e-label>
              <e-autocomplete
                :items="turmasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoTurmas"
                v-model="relacao.turma_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
                @change="getHorarioTurma(relacao)"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="2">
            <v-container class="px-0" fluid>
              <v-switch v-model="relacao.is_polivalencia" :label="`Polivalência`"></v-switch>
            </v-container>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider
              v-show="!relacao.is_polivalencia"
              name="Disciplina"
              rules="required"
              v-slot="{ errors }"
            >
              <e-label>Disciplina</e-label>
              <e-autocomplete
                :items="disciplinasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoDisciplinas"
                v-model="relacao.disciplina_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
            <ValidationProvider v-show="relacao.is_polivalencia" name="Disciplina">
              <e-label>Disciplinas</e-label>
              <v-autocomplete
                v-model="relacao.disciplinas_polivalencia"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :items="disciplinasList"
                v-show="!editing"
                chips
                label="Selecione as Disciplinas"
                multiple
                solo
              ></v-autocomplete>

              <div v-show="editing" v-for="(item, index) in relacao.disciplinas" :key="index">
                <v-chip x-small>{{ item.descricao }}</v-chip>
              </div>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <template>
              <v-container fluid>
                <v-checkbox
                  v-model="relacao.nao_compor_boletim_nem_ata"
                  :label="`Não compor BOLETIM/ATA`"
                ></v-checkbox>
              </v-container>
            </template>
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <ValidationProvider name="Carga horária (horas)" rules="required" v-slot="{ errors }">
              <e-label>Carga horária (horas)</e-label>
              <v-text-field
                type="number"
                v-model="relacao.carga_horaria"
                dense
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <v-dialog transition="dialog-top-transition" v-model="dialogVisible" max-width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="green white--text" v-bind="attrs" v-on="on" class="mt-7">
                  {{ editing ? "Editar Dias e horários" : "Adicionar Dias e horários" }}
                </v-btn>
              </template>
              <template>
                <v-card>
                  <v-toolbar color="primary" dark
                    >Vinculo de dias e horários
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogVisible = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-row class="mt-4">
                      <v-col class="pt-0 pb-0" cols="4">
                        <validation-provider name="Dias da semana" v-slot="{ errors }">
                          <e-label>Dias da semana</e-label>
                          <v-select
                            v-model="dia_da_semana"
                            :items="diasDaSemana"
                            :return-object="false"
                            :item-value="(value) => value"
                            :item-text="(value) => value.descricao"
                            dense
                            solo
                            label="selecione"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                      </v-col>

                      <v-col class="pt-0 pb-0" cols="4">
                        <validation-provider name="Horarios" v-slot="{ errors }">
                          <e-label>Horarios</e-label>
                          <v-select
                            v-model="horario_selecionado_id"
                            :items="horariosList"
                            :return-object="false"
                            :item-value="(value) => value"
                            :item-text="(value) => value.descricao"
                            dense
                            solo
                            label="selecione"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                      </v-col>

                      <v-col class="pt-7 pb-0" cols="4">
                        <v-btn
                          :disabled="!dia_da_semana || !horario_selecionado_id"
                          color="green"
                          class="white--text"
                          @click="adicionarHorario(relacoes.indexOf(relacao))"
                          >+ adicionar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-col v-if="!editing">
                    <v-timeline
                      v-show="relacoes.indexOf(relacao) == item.relacao_id"
                      align-top
                      dense
                      v-for="(item, i) in relacoesDiasHorarios"
                      :key="i"
                    >
                      <v-timeline-item
                        ><v-chip class="mt-1" color="green" text-color="white">
                          {{ item.dia.descricao }}
                          | {{ item.horario.descricao }}
                        </v-chip>
                        <v-btn
                          class="ml-2"
                          @click="deleteRelacaoDiaHorario(i, relacoes.indexOf(relacao))"
                          color="error"
                          fab
                          small
                          dark
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>

                  <v-col v-if="editing">
                    <v-timeline
                      align-top
                      dense
                      v-for="(item, i) in relacoesDiasHorariosEdit"
                      :key="i"
                    >
                      <v-timeline-item>
                        <v-chip class="mt-1" color="green" text-color="white">
                          {{ item.horario.descricao }} | {{ item.dia.descricao }}
                        </v-chip>

                        <v-btn
                          class="ml-2"
                          @click="deleteRelacaoDiaHorario(i, relacoes.indexOf(relacao))"
                          color="error"
                          fab
                          small
                          dark
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-card>
              </template>
            </v-dialog>
          </v-col>

          <v-col v-if="!editing" cols="3">
            <v-card>
              <v-card-title>
                <e-label>Relação de Dias e Horários</e-label>
              </v-card-title>
              <v-card-text>
                <div v-if="relacoesDiasHorarios.length === 0">
                  <p class="text-center">Não há horários selecionados</p>
                  <v-alert
                    color="error"
                    icon="mdi-alert"
                    outlined
                    text
                    v-show="msgDiasEHorariosObrigatorios"
                  >
                    Adicione pelo menos um horário
                  </v-alert>
                </div>
                <v-row v-else>
                  <v-col cols="7" class="text-left">
                    <span class="text-uppercase">Horário</span>
                  </v-col>
                  <v-col cols="" class="text-left">
                    <span class="text-uppercase">Dia</span>
                  </v-col>
                </v-row>
                <v-list>
                  <v-list-item v-for="(item, i) in relacoesDiasHorarios" :key="i">
                    <v-row>
                      <v-col cols="7" class="text-left">
                        {{ item.horario.descricao }}
                      </v-col>
                      <v-col cols="5" class="text-left">
                        {{ item.dia.descricao }}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="editing" cols="3">
            <v-card>
              <v-card-title>
                <e-label>Relação de Dias e Horários</e-label>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="7" class="text-left">
                    <span class="text-uppercase">Horário</span>
                  </v-col>
                  <v-col cols="" class="text-left">
                    <span class="text-uppercase">Dia</span>
                  </v-col>
                </v-row>
                <v-list>
                  <v-list-item v-for="(item, i) in relacoesDiasHorariosEdit" :key="i">
                    <v-row>
                      <v-col cols="7" class="text-left">
                        {{ item.horario.descricao }}
                      </v-col>
                      <v-col cols="5" class="text-left">
                        {{ item.dia.descricao }}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="relacoes.length > 1"
              class="mt-8"
              color="error"
              @click="() => (relacoes = relacoes.filter((relacaoObj) => relacaoObj !== relacao))"
              small
              :disabled="submittingForm"
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                class="ml-3"
                v-if="!editing"
                color="secondary"
                @click="adicionarRelacao"
                :disabled="submittingForm"
              >
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  watch: {
    is_polivalencia(value) {
      this.selecionarVariasDisciplinas(value);
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      msgDiasEHorariosObrigatorios: false,
      selectMultiplo: false,
      is_polivalencia: false,
      tipos: [],
      submittingForm: false,
      professoresList: [],
      turmasList: [],
      form: {},
      relacoes: [{}],
      disciplinasList: [],
      carregandoProfessores: false,
      carregandoTurmas: false,
      carregandoDisciplinas: false,

      relacoesDiasHorarios: [],
      relacoesDiasHorariosEdit: [],
      horario_selecionado_id: null,
      dia_da_semana: null,
      dialogVisible: false,
      diasDaSemana: [
        {
          id: 0,
          descricao: "Segunda",
        },
        {
          id: 1,
          descricao: "Terça",
        },
        {
          id: 2,
          descricao: "Quarta",
        },
        {
          id: 3,
          descricao: "Quinta",
        },
        {
          id: 4,
          descricao: "Sexta",
        },
        {
          id: 5,
          descricao: "Sábado",
        },
      ],
    };
  },
  methods: {
    adicionarHorario(relacao) {
      const data = {
        relacao_id: relacao,
        horario: this.horario_selecionado_id,
        dia: this.dia_da_semana,
      };

      if (!this.editing) {
        this.relacoesDiasHorarios.push(data);
      }

      if (this.editing) {
        this.relacoesDiasHorariosEdit.push(data);
      }
    },

    deleteRelacaoDiaHorario(i) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esse vinculo?",
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: false,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o vinculo");
              try {
                if (!this.editing) {
                  this.relacoesDiasHorarios.splice(i, 1);
                }

                if (this.editing) {
                  this.relacoesDiasHorariosEdit.splice(i, 1);
                }
                this.$toast.success("Vinculo deletado com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    getHorarioTurma(relacao) {
      this.getHorariosByTurmaAndTurno(relacao.turma_id);
    },

    async getHorariosByTurmaAndTurno(turmaId) {
      this.horariosList = await this.$services.horariosService.getHorariosDoTurnoDaTurma(turmaId);
      // console.log(this.horariosList);
    },

    selecionarVariasDisciplinas(val) {
      this.selectMultiplo = val;
    },
    adicionarRelacao() {
      const ultimaRelacao = this.relacoes[this.relacoes.length - 1];
      this.relacoes.push({ ...ultimaRelacao });
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.relacoesDiasHorarios.length > 0 || this.relacoesDiasHorariosEdit.length > 0) {
          if (this.editing) {
            const formVinculo = {
              ...this.relacoes[0],
              instrutor_id: this.form.instrutor_id,
              tipo_professor: this.form.tipo_professor,
              relacoesDiasHorarios: this.relacoesDiasHorariosEdit,
            };
            await this.$services.gestoesDeAulasService.atualizar(formVinculo);
            this.$toast.success("Vínculos criados/atualizados com sucesso");
          } else {
            const formRelacoes = this.relacoes.map((relacao, index) => ({
              ...relacao,
              instrutor_id: this.form.instrutor_id,
              tipo_professor: this.form.tipo_professor,
              is_polivalencia: relacao.is_polivalencia ? relacao.is_polivalencia : 0,
              relacoesDiasHorarios: this.relacoesDiasHorarios.filter((r) => r.relacao_id === index),
            }));

            const responseData = await this.$services.gestoesDeAulasService.criar(formRelacoes);
            this.relacoes = responseData.filter((item) => item.errors);
          }
          if (this.relacoes.length === 0 || this.editing) {
            this.$toast.success("Vínculos criados/atualizados com sucesso");
            this.$router.push({ name: "gestoesDeAulas" });
          }
        } else {
          this.msgDiasEHorariosObrigatorios = true;
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        this.professoresList = await this.$services.professoresService.syncAll();
        this.tipos = await this.$services.professoresService.tipos();
        this.turmasList = await this.$services.turmasService.syncAll();
        this.disciplinasList = await this.$services.disciplinasService.syncAll();

        this.horariosList = await this.$services.horariosService.syncAll();

        if (this.editing) {
          const vinculo = await this.$services.gestoesDeAulasService.gestaoGeralId(
            parseInt(this.$route.params.gestaoDeAulasId, 10)
          );

          this.getHorarioTurma(vinculo);

          this.relacoesDiasHorariosEdit = vinculo.relacoesDiasHorarios ?? [];

          this.form.is_polivalencia = vinculo.is_polivalencia;
          if (vinculo.tipo_professor) {
            this.form.tipo_professor = vinculo.tipo_professor;
          }
          if (vinculo) {
            this.form.instrutor_id = vinculo.instrutor_id;
            this.relacoes = [vinculo];
          }
        }
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
