import { render, staticRenderFns } from "./EDrawerMotivoAula.vue?vue&type=template&id=c96d6054&scoped=true"
import script from "./EDrawerMotivoAula.vue?vue&type=script&lang=js"
export * from "./EDrawerMotivoAula.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c96d6054",
  null
  
)

export default component.exports