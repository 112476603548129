<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione uma Disciplina </v-card-title>

        <v-select
          :items="disciplinas"
          item-text="descricao"
          item-value="id"
          v-model="disciplina_id"
          return-object
          label="Selecione uma disciplina"
          solo
          @change="verNotas()"
        ></v-select>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    gestaoDeAulasId: {
      type: Number,
    },
    disciplinas: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
  },
  data() {
    return {
      disciplinasArray: this.disciplinas,
      disciplina_id: null,
      disciplinasDaGestao: [],
    };
  },
  mounted() {},
  methods: {
    verNotas() {
      this.disciplina_id = this.disciplina_id.id;
      this.$router.push({
        name: "notas.circuitoNotas",
        params: { gestaoDeAulasId: this.gestaoDeAulasId, disciplinaId: this.disciplina_id },
      });
    },
  },
};
</script>
